export const SORT_DIRECTION_ENUM = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const EXTERNAL_ADDRESSES = {
  OPINIEO: 'https://opinieo.pl/karta-nfc/',
  EMAIL: 'kontakt@seotracking.pl',
  PHONE_NUMBER: '+48882825232',
  HELP_FORM: 'https://opinieo.pl/kontakt/',
};

export const PROMISES_STATUS_ENUM = {
  fullfilled: 'fulfilled',
  rejected: 'rejected',
};

export const AUTO_COMPLETE_TYPES = {
  EMAIL: 'email',
  PHONE: 'phone',
  NAME: 'name',
  URL: 'url',
};
