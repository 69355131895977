import { ReactComponent as HomeIcon } from '../../../../shared/assets/home.svg';
import { ReactComponent as ListIcon } from '../../../../shared/assets/map.svg';
import { ReactComponent as EditIcon } from '../../../../shared/assets/editProfile.svg';
import { ReactComponent as AddPost } from '../../../../shared/assets/grid.svg';
import { ReactComponent as Opinions } from '../../../../shared/assets/chat.svg';
import { ReactComponent as Camera } from '../../../../shared/assets/camera.svg';

export const sections = (t) => [
  {
    name: t('dashboard.dashboard'),
    Icon: HomeIcon,
    route: '/dashboard',
  },
  {
    name: t('dashboard.reports'),
    Icon: ListIcon,
    route: '/dashboard/report_list',
  },
  {
    name: t('dashboard.editBusinessCard'),
    Icon: EditIcon,
    route: '/dashboard/edit_business_profile',
    expandable: true,
    childrenName: [
      {
        name: t('dashboard.changeHistory'),
        route: '/dashboard/edit_business_profile/history',
        expandable: true,
      },
    ],
  },
  {
    name: t('dashboard.posts'),
    Icon: AddPost,
    route: '/dashboard/post',
  },
  {
    name: t('dashboard.opinions'),
    Icon: Opinions,
    route: '/dashboard/opinions',
    expandable: true,
    childrenName: [
      {
        name: t('dashboard.answerTemplates'),
        route: '/dashboard/opinions/answers',
        expandable: true,
      },
    ],
  },
  {
    name: t('dashboard.media'),
    Icon: Camera,
    route: '/dashboard/media',
  },
];

export const externalSections = (t) => [
  {
    name: t('dashboard.reports'),
    Icon: ListIcon,
    route: '/dashboard/report_list',
  },
];
